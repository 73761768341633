import {
	ROLES_LIST_ALL,
	ROLES_LIST_ALL_SUCCESS,
	ROLES_LIST_ALL_ERROR,
	MENU_ITEMS,
	MENU_ITEMS_SUCCESS
} from "../actions/roles";
import Vue from "vue";
import RolesService from '@/services/roles';
import PermissionCheck from '@/tools/permission-check';

const debug = process.env.NODE_ENV !== "production";

const state = {
	allRoles: [],
	menuItems: []
};

const getters = {
	getAllRoles: state => state.allRoles,
	getMenuItems: state => state.menuItems
};

const actions = {
	[ROLES_LIST_ALL]: ({ commit }) => {
		return new Promise((resolve, reject) => {
			commit(ROLES_LIST_ALL);
			RolesService.listAll().then(data => {
				commit(ROLES_LIST_ALL_SUCCESS, data.roles);
				resolve(data);
			})
			.catch(error => {
				commit(ROLES_LIST_ALL_ERROR, error);
				reject(error);
			});
		});
	},
	[MENU_ITEMS]: ({ commit }) => {
		commit(MENU_ITEMS);
		let template_ui = {
			_name: 'CSidebarNavDropdown',
			name: 'UI',
			route: '/base',
			icon: 'cil-puzzle',
			items: [
				{
					name: 'Colors',
					to: '/theme/colors',
					icon: 'cil-drop'
				},
				{
					name: 'Typography',
					to: '/theme/typography',
					icon: 'cil-pencil'
				},
				{
					name: 'Breadcrumbs',
					to: '/base/breadcrumbs'
				},
				{
					name: 'Cards',
					to: '/base/cards'
				},
				{
					name: 'Carousels',
					to: '/base/carousels'
				}, 
				{
					name: 'Collapses',
					to: '/base/collapses'
				},
				{
					name: 'Forms',
					to: '/base/forms'
				},
				{
					name: 'Jumbotrons',
					to: '/base/jumbotrons'
				},
				{
					name: 'List Groups',
					to: '/base/list-groups'
				},
				{
					name: 'Navs',
					to: '/base/navs'
				},
				{
					name: 'Navbars',
					to: '/base/navbars'
				},
				{
					name: 'Paginations',
					to: '/base/paginations'
				},
				{
					name: 'Popovers',
					to: '/base/popovers'
				},
				{
					name: 'Progress Bars',
					to: '/base/progress-bars'
				},
				{
					name: 'Switches',
					to: '/base/switches'
				},
				{
					name: 'Tables',
					to: '/base/tables'
				},
				{
					name: 'Tabs',
					to: '/base/tabs'
				},
				{
					name: 'Tooltips',
					to: '/base/tooltips'
				},
				{
					name: 'Buttons',
					to: '/buttons/standard-buttons',
					icon: 'cil-cursor',
				},
				{
					name: 'Button Dropdowns',
					to: '/buttons/dropdowns'
				},
				{
					name: 'Button Groups',
					to: '/buttons/button-groups'
				},
				{
					name: 'Brand Buttons',
					to: '/buttons/brand-buttons'
				},
				{
					name: 'Charts',
					to: '/charts',
					icon: 'cil-chart-pie'
				},
				{
					name: 'CoreUI Icons',
					to: '/icons/coreui-icons',
					icon: 'cil-star',
					badge: {
						color: 'info',
						text: 'NEW'
					}
				},
				{
					name: 'Brands',
					to: '/icons/brands'
				},
				{
					name: 'Flags',
					to: '/icons/flags'
				},
				{
					name: 'Alerts',
					to: '/notifications/alerts',
					icon: 'cil-bell'
				},
				{
					name: 'Badges',
					to: '/notifications/badges'
				},
				{
					name: 'Modals',
					to: '/notifications/modals'
				},
				{
					name: 'Widgets',
					to: '/widgets',
					icon: 'cil-calculator',
					badge: {
						color: 'primary',
						text: 'NEW',
						shape: 'pill'
					}
				},
				{
					name: 'Login',
					to: '/pages/login',
					icon: 'cil-star',
				},
				{
					name: 'Register',
					to: '/pages/register'
				},
				{
					name: 'Error 404',
					to: '/pages/404'
				},
				{
					name: 'Error 500',
					to: '/pages/500'
				}
			]
		};
		let main_ui = [
			{
				_name: 'CSidebarNav',
				_children: [
					{
						_name: 'CSidebarNavItem',
						name: 'Nadzorna plošča',
						to: '/dashboard',
						icon: 'cil-speedometer',
					}
				]
			}
		];
		let ebills_ui = null;
		if (PermissionCheck.can('ebills-read')) {
			ebills_ui = {
				_name: 'CSidebarNavItem',
				name: 'eRačuni',
				to: '/eracuni/list',
				icon: 'cil-cursor',
			};
		}
		if (ebills_ui != null) {
			main_ui[0]._children.push(ebills_ui);
		}
		let spOrders_ui = null;
		if (PermissionCheck.can('sp-orders-read')) {
			spOrders_ui = {
				_name: 'CSidebarNavItem',
				name: 'Nadomestni deli',
				to: '/narocanjend/list',
				icon: 'cil-soccer'
			};
		}
		/*if (spOrders_ui != null) {
			main_ui[0]._children.push(spOrders_ui);
		}*/
		let clients_ui = null;
		if (PermissionCheck.can('clients-read')) {
			clients_ui = {
				_name: 'CSidebarNavItem',
				name: 'Kupci',
				to: '/entities/clients/list',
				icon: 'cil-address-book'
			};
		}
		let users_ui = null;
		if (PermissionCheck.can('users-read')) {
			users_ui = {
				_name: 'CSidebarNavItem',
				name: 'Uporabniki',
				to: '/entities/users/list',
				icon: 'cil-group'
			};
		}
		if (clients_ui != null || users_ui != null) {
			main_ui[0]._children.push({
				_name: 'CSidebarNavTitle',
				_children: ['Šifranti']
			});
		}
		if (clients_ui != null) {
			main_ui[0]._children.push(clients_ui);
		}
		if (users_ui != null) {
			main_ui[0]._children.push(users_ui);
		}
		if(debug) {
			main_ui[0]._children.push({
				_name: 'CSidebarNavDivider',
				_class: 'm-2'
			});
			main_ui[0]._children.push(template_ui);
		}
		commit(MENU_ITEMS_SUCCESS, main_ui);
	}
};

const mutations = {
	[ROLES_LIST_ALL]: state => {
		state.status = 'loading';
	},
	[ROLES_LIST_ALL_SUCCESS]: (state, roles) => {
		state.status = 'success';
		Vue.set(state, 'allRoles', roles);
	},
	[ROLES_LIST_ALL_ERROR]: state => {
		state.status = 'error';
		Vue.set(state, 'roles', null);
	},
	[MENU_ITEMS]: state => {
		state.status = 'loading';
	},
	[MENU_ITEMS_SUCCESS]: (state, menuItems) => {
		state.status = 'success';
		Vue.set(state, 'menuItems', menuItems);
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};